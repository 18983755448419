<template>
  <div class="statis">
    <el-table :data="statisticsList" style="width: 100%" border>
      <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column>
      <el-table-column prop="name" label="服务名称" align="center">
        <template slot="header">
          服务名称
        </template>
      </el-table-column>
      <el-table-column prop="adminCount" label="管理员" align="center">
        <template slot="header">
          管理员<noun-help content="管理员查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="merchantCount" label="商户" align="center">
        <template slot="header">
          商户<noun-help content="商户查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="总数" align="center">
        <template slot="header">
          总数<noun-help content="查询的总次数"/>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import nounHelp from '@/components/noun-help'
export default {
    components:{ nounHelp },
  props: {
    statisticsList: {
      type: Array,
      default: () => []
    }
  },
};
</script>

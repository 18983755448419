<template>
  <div class="tool-box">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: []
    };
  },
  methods: {
    searchList() {
      let [startTime = '', endTime = ''] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit('search', param);
    }
  }
};
</script>
<style lang="scss" scoped>
.tool-box{
    text-align: left;
}
</style>

<template>
  <div class="nounHelp">
    <el-tooltip :content="content" placement="top">
      <i class="el-icon-question"></i>
    </el-tooltip>
  </div>
</template>

<script>
  export default {
    props:{
      content:{
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nounHelp{
    display: inline;
  }
</style>
